import React from "react";
import nkt from ".././Components/img/nkt banner.jpg"
import pman from ".././Components/img/loads.jpg"


const NKT = () => {
    const YouVid = [
        {
            vidlink: "https://www.youtube.com/embed/Yo6QUXWmb3c"
        },
        {
            vidlink: "https://www.youtube.com/embed/rF-zfYQAoDA"
        },
        {
            vidlink: "https://www.youtube.com/embed/EGWbyyUGJOo"
        },
    ]
    return (
    <div className="md:pl-20 md:pr-20 w-[100%] items-center justify-center grid pt-2">
    <div className=" md:w-[60%] p-5 flex flex-col justify-self-center shadow-lg mt-28">
        <h1 style={{fontSize:40, color: "#b30000", fontWeight: "", paddingTop: 30 }}  className=" text-center">THE NEW KINGDOM TRUMPET</h1>
        <div className="self-center">
        <a target="_blank"  href="https://nktnewsonline.com" className=" text-[#006eff]">New Kingdom Trumpet Official Blog WebSite</a>
        </div>
        <img src={nkt} alt="" className="lg:w-[60%] self-center w-96 lg:h-[60%] my-10 flex-wrap" />

        <h6 style={{ color: "#333", padding: 10, }}  className="text- font-">
        The Book of Genesis chapters 1 and 2 reveals that everything one beholds in the world including man was created by God with the words of His mouth. The Gospel of John chapter 1 verses 1 to 15 substantiated this, even with the inclusion that the word itself became flesh and lived in the world with man.
        </h6>
        <h6 style={{ color: "#333", padding: 10, }}  className="font-">
        Even when Adam and Eve faltered, it took the same word to alter things. Therefore, in this last dispensation, the recreation and reconstruction of everything including the fallen man is through the word of God. And this is why the Holy Writ did say, “And this Gospel of the Kingdom shall be preached in all the world for a witness unto all nations; and then shall the end come” (Matt. 24: 14).
                </h6>
        <h6 style={{ color: "#333", padding: 10, }}  className="text- font-">
        In this last dispensation, The Ancient of Days, Great Leader Olumba Olumba Obu, The Sole Spiritual Head of the Universe and His Christ, His Holiness Olumba Olumba Obu, The King of kings and The Lord of lords who bodily constitute The Holy Trinity are here on earth with man. The Everlasting Gospel, that is, His holy word, is being preached and of necessity must reach to every nooks and crannies of the world, so that mankind would have no excuse (Rev. 14: 6-7). This forms the foundation to the establishing of this Media House and Newspaper called, “THE NEW KINGDOM TRUMPET” by The Holy Father as an outreach to the depraved man and world. Is it not written in 1 Thessalonians 4: 16 that, “For the Lord Himself shall descend from heaven with a shout, with the voice of the archangel, and with the trump of God: and the dead in Christ shall rise first”?
                </h6>
        <h6 style={{ color: "#333", padding: 10, }}  className="text- font-">
        As a Publishing Organisation and Official Organ of the Brotherhood of the Cross and Star, the New Kingdom of God, The New Kingdom Trumpet (NKT) publishes mainly the injunctions and supernatural teachings of Holy Father Olumba Olumba Obu. It also covers testimonies, miracles, signs and wonders, and revelations made by brethren, both the baptised and non-baptised. 
                </h6>
        <h6 style={{ color: "#333", padding: 10, }}  className="text- font-">
        Established in the year 1984, with her maiden edition produced on 12th April, 1984, the organisation was known as “The Herald of the New Kingdom”. Then sometime in 1994, it was rechristened “The New Kingdom Trumpet”. On 6th October, 2010, she was incorporated with the Corporate Affairs Commission, Abuja, Nigeria as a corporate entity.
                        </h6>
    </div>
    <div className=" md:w-[60%] p-5 flex flex-col justify-self-center items-center shadow-lg mt-20">
        <img src={pman} alt="" className="lg:w-[60%] self-center w-96 my-5 flex-wrap"   />
                        <h1 style={{fontSize:26, color: "#b30000", fontWeight: "", paddingTop: 10 }}  className=" text-center">Mission:</h1>

        <h6 style={{ color: "#333", padding: 0, }}  className="text- w-[60%]">
        To propagate the divine teachings of The Supernatural Teacher and The Promised Comforter, Great Leader Olumba Olumba Obu and His Christ, His Holiness Olumba Olumba Obu, The King of kings and The Lord of lords as absolutely instituted and inspired by our Lord Jesus Christ, which centres on love, truth, forgiveness and the salvation of the world to impact positively on all nations, tribes and tongues.
                                </h6>

                        <h1 style={{fontSize:26, color: "#b30000", fontWeight: "", paddingTop: 10 }}  className=" text-center">Vision:</h1>

        <h6 style={{ color: "#333", padding: 10, }}  className="font- w-[60%]">
        To let the world know that the Brotherhood of the Cross and Star is the long expected Kingdom of God, and is the modern day’s Noah’s Ark through its teachings and the exemplary character of members.
                        </h6>

                        <h1 style={{fontSize:26, color: "#b30000", fontWeight: "", paddingTop: 10 }}  className=" text-center">Motto:</h1>

        <h6 style={{ color: "#333", padding: 10, }}  className="font- self-center">
        Recreating the world with the Word!
                                </h6>
    </div>

    <div className=" md:w-[60%] p-5 flex flex-col justify-self-center shadow-lg mt-28">
                        <h1 style={{fontSize:40, color: "#b30000", fontWeight: "", paddingTop: 10 }}  className=" text-center">Aims and Objectives:</h1>
        
        <h6 style={{ color: "#333", padding: 10, }}  className="">
        •	To carry on business as magazines proprietors and publishers, to own, print and publish newspaper periodicals, serials, newsletters, journals and publications of every description.
                                        </h6>
        <h6 style={{ color: "#333", padding: 10, }}  className="">
        •	To carry on the business of printing in any or all of its forms, to carry on business as proprietors and managers of printing presses of all types, whether in all description, to carry on the business of events management and award organising in all descriptions, to arrange, organise sporting events but not limited to football, to engage in fashion design agents.

                                </h6>
        <h6 style={{ color: "#333", padding: 10, }}  className="">
        •	To carry on business as redactors, revisers, editors, copy editors, readers, proofreaders, bibliographers, adapters, translators, artists, photographers, illustrators, designers and publishing personnel of every description; and to commission work to independent publishing personnel where desirable.
                                        </h6>
        <h6 style={{ color: "#333", padding: 10, }}  className="">
        •	To carry on the business of general trading, importers, exporters, distributors, suppliers, wholesalers, retailers, buyers and sellers of general goods and dealers in goods and services of every descriptions, to engage in the business of film and music production, to carry on the business of a construction company, to provide construction services of every description, including planning, design, erection, improvement, repair, alteration, maintenance and demolition of construction works.
                                        </h6>
        <h6 style={{ color: "#333", padding: 10, }}  className="">
        •	To borrow or raise money as the company shall think fit: debentures or debenture stock, to amalgamate with other company; to produce the company to be registered in any country or place outside Nigeria.
                                        </h6>
        <h6 style={{ color: "#333", padding: 10, }}  className="">
        •	To do all such other things as may be considered to be incidental or conductive to the attainment of the above objects or any of them.
                                </h6>
        <h6 style={{ color: "#333", padding: 10, }}  className="">
        This is summarily The New Kingdom Trumpet Limited. 
                                        </h6>
        <h6 style={{ color: "#333", padding: 10, }}  className="">
        You are welcome to our page.
                                                </h6>
    </div>

        <div className=" w-[50%] p-5 flex flex-col justify-self-center shadow-lg mt-12">

                                                <h1 style={{fontSize:40, color: "#b30000", fontWeight: "", paddingTop: 30 }}  className=" text-center">Other online details:</h1>

        <h6 style={{ color: "#333", padding: 30, }}  className="">
        •	Website: www.nktnewsonline.com
                                                </h6>
        <h6 style={{ color: "#333", padding: 30, }}  className="">
        •	Facebook: NEW KINGDOM TRUMPET.
                                                </h6>
        <h6 style={{ color: "#333", padding: 30, }}  className="">
        •	Facebook Page: New Kingdom Trumpet.
                                                        </h6>
        <h6 style={{ color: "#333", padding: 30, }}  className="">
        •	Twitter: @nktnewsonline.
                                                        </h6>
        <h6 style={{ color: "#333", padding: 30, }}  className="">
        •	Email: newkingdomtrumpet@gmail.com and ……………………………………
                                                        </h6>
        <h6 style={{ color: "#333", padding: 30, }}  className="">
        Thank You Father.
                                                                </h6>
        </div>
        
        <div className="flex w-[100%] items-center justify-center">


            <div class="grid sm:grid-cols-3 grid-cols-1 mt-10 gap-5 mb-10">
            {YouVid.map((item,index)=>(
                <div>
                    <iframe src={item.vidlink} className='w-fit h-64 self-center'/>
                </div>
            ))}
            </div>
        </div>
    </div>
    )
}

export default NKT;