import React, { useState } from 'react';
import up from "../img/up.jpg";
import foot3 from '../images/foot3.jpg';
import { useSwipeable } from 'react-swipeable';
import foot4 from '../images/foot4.jpg';
import './Megas.css'; // You can create a separate CSS file for styling
import { Link } from 'react-router-dom';

const slidesData = [
  {
    imageUrl: foot3,
    // imageUrl: 'https://placekitten.com/300/200',
    date:"March 2024",
    tit: "DIVINE ASSURANCE",
    text: 'Divine assurance unto Christhood is a profound concept that reflects God"s promise to prepare and transform members of the church into Christ-like beings. It encompasses a spiritual journey where believers are guided, empowered, and molded by God to embody the qualities and characteristics of Christ. This assurance serves as a testament to God"s unwavering commitment to His people, fostering a deeper connection and purpose within the faith community.',
  },
  {
    // imageUrl: ,
    imageUrl: 'https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/images%2Fpapasupremesignature.jpg?alt=media&token=88dbbc57-3530-4a16-9557-8d798bfce564',
    date:"March 2024",
    tit: "Out Pouring Of The Holy Spirit",
    text: 'On every Easter Monday of the year, it is a common practice in Brotherhood of the Cross and Star (BCS) that all faithful assemble before The Holy Father at the BCS World Headquarters, 34 Ambo Great Hall, to have The Holy Spirit in them revived by The Holy Spirit personified, Leader Olumba Olumba Obu Himself. This annual spiritual exercise is to reawaken their spirits and bodies, keeping them fit, emboldened, and empowered for the Great Commission.',
  },
  {
    imageUrl: foot4,
    date:"14th April 2024",
    // imageUrl: 'https://placekitten.com/300/201',
    tit: "The King of Kings And The Lord of lords Coronation",
    text: 'The coronation of the King of kings and Lord of lords, His Holiness Olumba Olumba Obu was a monumental event that took place in two strata. The detail of the events that led to, and occurred during both phases of the Divine Coronation are as follows.',
  },
];

const Megas = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slidesData.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slidesData.length) % slidesData.length);
  };

  const handlers = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: prevSlide,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className="slider-container"  {...handlers}>
      <div>
      <img
        src={slidesData[currentSlide].imageUrl}
        alt={`Slide ${currentSlide + 1}`}
        className="slider-image"
      />
      <h2 class="text-base mt-5 font-medium text-indigo-300 mb-1">
                    {slidesData[currentSlide].date}
      </h2>
      <p className="slider-tit">{slidesData[currentSlide].tit}</p>
      <p className="slider-text">{slidesData[currentSlide].text}</p>
      <div class="flex items-center flex-wrap ">
                    <Link 
                       onClick={() => {
                        window.scrollTo(0, 0);
                      }}  
                     to="/UpcomingEvents" class="text-indigo-300 inline-flex items-center md:mb-2 lg:mb-0">
                      Read More
                      <svg
                        class="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </Link>
        </div>
      <div className="arrow-buttons">
        <button onClick={prevSlide} className="arrow-button">
          {'<'}
        </button>
        <button onClick={nextSlide} className="arrow-button">
          {'>'}
        </button>
      </div>
      </div>
      <div className="dot-indicators">
        {slidesData.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentSlide ? 'active' : ''}`}
            onClick={() => setCurrentSlide(index)}
          />  
        ))}
      </div>
    </div>
  );
};

export default Megas;
