import React, { useEffect, useRef, useState } from 'react'
import { TiArrowSortedUp } from "react-icons/ti"
import Son from "../src/aw.png";
// import Son from "../src/Radio.png";
import SonMan from "../src/Radio3.png";

export default function Radio() {
    const [isVisible, setIsVisible] = useState(false);

    const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

//   const playAudio = () => {
//     if (!audioRef.current) {
//       return;
//     }

//     if (isPlaying) {
//       audioRef.current.pause();
//     } else {
//       audioRef.current.play();
//     }

//     setIsPlaying(!isPlaying);
//   };
  const [isLoading, setIsLoading] = useState(true);

  const playAudio = () => {
    if (!audioRef.current) {
      return;
    }
  
    // if (isPlaying) {
    //   audioRef.current.stop();
    // } else {
      audioRef.current.play();
    // }
  
    setIsPlaying(!isPlaying);
  };

  const handleReload = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    audioRef.current.load();
    // audioRef.current.play();
  };
  const handleUnload = () => {
    audioRef.current.unload();
  };
  
//   const handleLoad = () => {
//     setIsLoading(false);
//   };
  
//   useEffect(() => {
//     audioRef.current.addEventListener("load", handleLoad);
//     return () => {
//       audioRef.current.removeEventListener("load", handleLoad);
//     };
//   }, []);
    return (
        <>
        {/* { isPlaying ? */}

            <div 
            // onClick={playAudio}
            className='fixed bottom-20 left-2 md:left-8 cursor-pointer  justify-center items-center grid'>
                 {/* {isPlaying ? "Pause" : "Play"} */}
            <audio
            ref={audioRef}
            className='cursor-pointer animate-pulse '
            src="https://stream-151.zeno.fm/qqukv2cm3p8uv?zs=tn1avVlnSQu1IozNVaQzrw"
            alt="https://stream-151.zeno.fm/qqukv2cm3p8uv?zs=tn1avVlnSQu1IozNVaQzrw"
            controls
            
            onPlay={playAudio}
            onPause={handleReload}
            />
           
                    <img src={Son} alt='okay' className='h-10 w-auto shadow-2xl drop-shadow-2xl  rounded-lg cursor-pointer animate-pulse'/>
            </div>
            {/* : */}
        {/* // } */}
        </>


    )
}
