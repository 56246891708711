import { Link } from "react-router-dom";
import '../index.css';
import React, { useState, useEffect } from "react";
import { getPosts } from "./DUM";
import dateFormat from "dateformat";
import Markdown from "markdown-to-jsx";
import up from "../img/up.jpg";
import foot3 from '../images/foot3.jpg';
import foot4 from '../images/foot4.jpg';
import Megas from "./Megas";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
const PostCard = ({ post, createdAt }) => {
  const [showFullPost, setShowFullPost] = useState(false);

  const handleClick = (event) => {
    // event.preventDefault();
    setShowFullPost(true);
  };

  const handleClick2 = (event) => {
    // event.preventDefault();
    setShowFullPost(false);
  };

  const cardClassName = showFullPost ? "h-auto" : "h-[400px]";
  return (
    <div className="md:p-6  p-1">
      <div
        className={`rounded-lg overflow-hidden text-center shadow-md bg-white w-80 ${cardClassName}`}
      >
        <img
          src={post.thumbnail}
          alt={post.title}
          className="h-32 w-full object-contain"
        />
        <div className="p-6">
          <p className="text-gray-500 text-[15px] flex justify-between">
            <h2 className="text-2xl text-gray-800 font-bold mb-2">
              {post.title}
            </h2>
            {dateFormat(createdAt, "mediumDate")}
          </p>
          {showFullPost ? (
            <Markdown className="text-gray-600 text-base z-50">
              {post.content}
            </Markdown>
          ) : (
            <p className="text-gray-600 text-base truncate whitespace-normal break-words h-5">
              {post.content}
            </p>
          )}
        </div>
        {!showFullPost && (
          <Link
            to={"/Blog"}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            // onClick={handleClick}
          >
            Read More
          </Link>
         )}

        {showFullPost && (
          <h1
            to={`/posts/${post.slug}`}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-[100px] mb-[10px] ml-32"
            onClick={handleClick2}
          >
            Close
          </h1>
        )}
      </div>
    </div>
  );
};

const Mega=()=>{

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const { posts } = await getPosts();
      setPosts(posts);
    };

    fetchPosts();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };


    return(

        <div className="text w-[100%]">
        <div className=" md:pl-20 md:pr-20">
        {/* <motion.div
    // className="md:pl-16 md:pr-16"
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{
        duration: 2,
        delay: 0.3,
        ease: [0, 0.71, 0.2, 1.01]
    }}
    >   
      <div className="flex flex-wrap justify-between p-10">
        {posts.slice(0, 3).map((post) => (
          <div key={post.id} className="w-full md:w-1/3 mb-3 px-2">
            <PostCard post={post} />
          </div>
        ))}
      </div>
    </motion.div> */}
          {/* <a href="/UpcomingEvents" className="cursor-pointer">

                <img className="animate-pulse"  src={Son} style={{ width: '100%', padding: 0,  marginTop: 0    }}/>
          </a> */}
          <Link  
             onClick={() => {
              window.scrollTo(0, 0);
            }}  
           to="/UpcomingEvents" className="mt-20 cursor-pointer flex justify-center">

                <img className=""  src={up} style={{ width: '60%', padding: 0,  marginTop: 0    }}/>
          </Link>
   

              {/* <h1 style={{fontSize:40, color: "#b30000", fontWeight: "bold", paddingTop: 20, paddingLeft: 10, paddingRight: 10 }}  className=" text-center pt-28"> */}
              {/* <h1 style={{}} className="text-6xl pt-28 p-5  text-center font-bold bg-gradient-to-r from-pink-400 to-purple-300 hover:from-pink-500 text-transparent bg-clip-text"> */}
              {/* <h1 style={{}} className="text-6xl pt-5 p-5  text-center bg-[#b30000] font-bold bg-gradient-to-r from-[#b30000]-400 via-purple-500 to-[#b30000]-800 text-transparent bg-clip-text">
UPCOMING EVENTS
</h1> */}

{/* <h6 className="text-1xl text-center  font-bold  text-gray-700 ">
              The BCS Bethel is Open 24hrs & And there's an event for every single
              day 
              </h6> */}

         

      <section class="hidden md:inline-block items-center text-gray-600  pb-0 rounded">
        <div class="container px-5 py-10 mx-auto">
          <div class="text-center mb-12">
          </div>
          <div class="flex flex-wrap -m-4">
     
          <div class="p-4 cursor-pointer sm:w-1/2 lg:w-1/3">
              <div class="h-full border-2 pr-5 pt-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden duration-300 shadow-lg hover:shadow-gray-900">
              <Link  
                 onClick={() => {
                  window.scrollTo(0, 0);
                }}  
               to="/UpcomingEvents">

                {/* <img
                  class="lg:h-72 h-48 w-full   object-cover object-center"
                        src="https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/images%2FHM-ESU-OLUMBA.jpeg?alt=media&token=f1c1dca4-8c6d-4b51-9492-f64d3694cc9b"                  alt="blog"
                /> */}
                <img src={foot3} alt="" class="lg:h-72 h-48 w-full object-cover object-center" />
                <div class="p-6">
                  <h2 class="text-base font-medium text-indigo-300 mb-1">
                 25th March 2024
                  </h2>
                  <h1 class="text-xl font-semibold mb-3 text-center">
                  Divine Assurance..

                  </h1>
                  <p class="leading-relaxed ml-4 mb-3">
                  Divine assurance unto Christhood is a profound concept that reflects God's promise to prepare and transform members of the church into Christ-like beings. It encompasses a spiritual journey where believers are guided, empowered, and molded by God to embody the qualities and characteristics of Christ. This assurance serves as a testament to God's unwavering commitment to His people, fostering a deeper connection and purpose within the faith community.
                  </p>
                  <div class="flex items-center flex-wrap ">
                    <Link 
                       onClick={() => {
                        window.scrollTo(0, 0);
                      }}  
                    to="/UpcomingEvents" class="text-indigo-300 inline-flex items-center md:mb-2 lg:mb-0">
                      Read More
                      <svg
                        class="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </Link>
                  </div>
                </div>
                </Link>
              </div>
            </div>
          {/* <div class="p-4 cursor-pointer sm:w-1/2 lg:w-1/3">
              <div class="h-full border-2 pr-5 pt-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden duration-300 shadow-lg hover:shadow-gray-900">
              <Link to="/UpcomingEvents">

                <img
                  class="lg:h-72 h-48 w-full   object-cover object-center"
                        src="https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/images%2FHM-ESU-OLUMBA.jpeg?alt=media&token=f1c1dca4-8c6d-4b51-9492-f64d3694cc9b"                  alt="blog"
                />
                <div class="p-6">
                  <h2 class="text-base font-medium text-indigo-300 mb-1">
                  1st October 2023
                  </h2>
                  <h1 class="text-xl font-semibold mb-3 text-center">
                  Holy Mother's Glor..

                  </h1>
                  <p class="leading-relaxed ml-4 mb-3">
                  The day the Holy Mother was transform will remain evergreen in the minds and souls 
                  of every true BCS member. The reason the day is remembered in BCS. Holy mother was 
                  the pillar, backbone and building rock of BCS. She is the reason the kingdom is spreading 
                  fast and wide. She is the epitome of blessedness, her love and mercy for humanity is incomparable....
                  </p>
                  <div class="flex items-center flex-wrap ">
                    <Link to="/UpcomingEvents" class="text-indigo-300 inline-flex items-center md:mb-2 lg:mb-0">
                      Read More
                      <svg
                        class="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </Link>
                  </div>
                </div>
                </Link>
              </div>
            </div> */}
     
            <div class="p-4 cursor-pointer sm:w-1/2 lg:w-1/3">
              <div 
              class="h-full border-2 border-gray-200 pr-5 pt-2 border-opacity-60 rounded-lg overflow-hidden duration-300 shadow-lg hover:shadow-gray-900">
                <Link
                   onClick={() => {
                    window.scrollTo(0, 0);
                  }}  
                 to="/UpcomingEvents">
                
                <img
                  class="lg:h-72 h-48 w-full  object-cover object-center"
                  src="https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/images%2Fpapasupremesignature.jpg?alt=media&token=88dbbc57-3530-4a16-9557-8d798bfce564"
                  alt="blog"
                />
                <div class="p-6">
                  <h2 class="text-base font-medium text-indigo-300 mb-1">
                    1st April 2024
                  </h2>
                  <h1 class="text-2xl font-semibold mb-3 text-center">
                  Out Pouring Of The Holy Spirit
                                    </h1>
                  <p class="leading-relaxed ml-4 mb-3">
                  On every Easter Monday of the year, it is a common practice in Brotherhood of the Cross and Star (BCS) that all faithful assemble before The Holy Father at the BCS World Headquarters, 34 Ambo Great Hall, to have The Holy Spirit in them revived by The Holy Spirit personified, Leader Olumba Olumba Obu Himself. This annual spiritual exercise is to reawaken their spirits and bodies, keeping them fit, emboldened, and empowered for the Great Commission.
                  </p>
                  <div class="flex items-center flex-wrap ">
                    <Link 
                       onClick={() => {
                        window.scrollTo(0, 0);
                      }}  
                    to="/UpcomingEvents" class="text-indigo-300 inline-flex items-center md:mb-2 lg:mb-0">
                      Read More
                      <svg
                        class="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </Link>
                  </div>
                </div>
              </Link>
              </div>
            </div>
            {/* <div class="p-4 cursor-pointer sm:w-1/2 lg:w-1/3">
              <div 
              class="h-full border-2 border-gray-200 pr-5 pt-2 border-opacity-60 rounded-lg overflow-hidden duration-300 shadow-lg hover:shadow-gray-900">
                <Link to="/UpcomingEvents">
                
                <img
                  class="lg:h-72 h-48 w-full  object-cover object-center"
                  src="https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/images%2Fpapasupremesignature.jpg?alt=media&token=88dbbc57-3530-4a16-9557-8d798bfce564"
                  alt="blog"
                />
                <div class="p-6">
                  <h2 class="text-base font-medium text-indigo-300 mb-1">
                    20th November 2023
                  </h2>
                  <h1 class="text-2xl font-semibold mb-3 text-center">
                  Divine Manifestation of King of Kings And Lord of Lords
                                    </h1>
                  <p class="leading-relaxed ml-4 mb-3">
                  Just like the Christmas day is celebrated to mark the 
                  birth of our Lord Jesus Christ, this day is celebrated
                   and will always be celebrated forever in Brotherhood of the 
                   Cross and Star because it is the day which the glorious king of
                    Heaven and the reincarnated Christ Great His Holiness Olumba Olumba 
                    Obu was 
                  </p>
                  <div class="flex items-center flex-wrap ">
                    <Link to="/UpcomingEvents" class="text-indigo-300 inline-flex items-center md:mb-2 lg:mb-0">
                      Read More
                      <svg
                        class="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </Link>
                  </div>
                </div>
              </Link>
              </div>
            </div> */}
            
            <div class="p-4 cursor-pointer sm:w-1/2 lg:w-1/3 ">
              <div class="h-full border-2 pr-5 pt-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden duration-300 shadow-lg hover:shadow-gray-900">
              <Link
                 onClick={() => {
                  window.scrollTo(0, 0);
                }}  
               to="/UpcomingEvents">
                {/* <img
                  class="lg:h-72 h-48 w-full object-cover object-center"
                  src="https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/gallery%2F1.jpg?alt=media&token=6686be68-b245-409a-a680-c1aa49fda2c3"             
                  alt="blog"
                /> */}
                <img src={foot4} alt="" class="lg:h-72 h-48 w-full object-cover object-center" />
                <div class="p-6">
                  <h2 class="text-base font-medium text-indigo-300 mb-1">
                  14th April 2024
                  </h2>
                  <h1 class="text-2xl font-semibold mb-3 text-center">
                  The King of Kings And The Lord of lords Coronation
                  </h1>
                  <p class="leading-relaxed ml-4 mb-3">
                  The coronation of the King of kings and Lord of lords, His Holiness Olumba Olumba Obu was a monumental event that took place in two strata. The detail of the events that led to, and occurred during both phases of the Divine Coronation are as follows;
                  </p>
                  <div class="flex items-center flex-wrap ">
                    <Link
                       onClick={() => {
                        window.scrollTo(0, 0);
                      }}  
                     to="/UpcomingEvents" class="text-indigo-300 inline-flex items-center md:mb-2 lg:mb-0">
                      Read More
                      <svg
                        class="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </Link>
                  </div>
                </div>
                </Link>
              </div>
            </div>
            {/* <div class="p-4 cursor-pointer sm:w-1/2 lg:w-1/3 ">
              <div class="h-full border-2 pr-5 pt-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden duration-300 shadow-lg hover:shadow-gray-900">
              <Link to="/UpcomingEvents">
                <img
                  class="lg:h-72 h-48 w-full object-cover object-center"
                  src="https://firebasestorage.googleapis.com/v0/b/bcs-website-assets.appspot.com/o/gallery%2F1.jpg?alt=media&token=6686be68-b245-409a-a680-c1aa49fda2c3"             
                  alt="blog"
                />
                <div class="p-6">
                  <h2 class="text-base font-medium text-indigo-300 mb-1">
                  30th December 2023
                  </h2>
                  <h1 class="text-2xl font-semibold mb-3 text-center">
                  Divine Manifestation of the Ancient of Days
                  </h1>
                  <p class="leading-relaxed ml-4 mb-3">
                  Leader Olumba Olumba Obu, The Sole Spiritual Head of the Universe, is the Founder, 
                  Leader, and Sustainer of the Brotherhood of the Cross and Star Kingdom. His mission on 
                  earth is to do what nobody, angel or spirit has ever done in the world. He came to teach and 
                  lead man into God’s Kingdom, to sanctify humanity, to establish the Kingdom of God on Earth. His
                   Physical Manifestation was on 30th December, 1918, 
                  </p>
                  <div class="flex items-center flex-wrap ">
                    <Link to="/UpcomingEvents" class="text-indigo-300 inline-flex items-center md:mb-2 lg:mb-0">
                      Read More
                      <svg
                        class="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                      </svg>
                    </Link>
                  </div>
                </div>
                </Link>
              </div>
            </div> */}


          </div>
        </div>
      </section>
      <div className="md:hidden mt-20">
        <Megas  />
      </div>
    </div>
    </div>
    )
}
export default Mega;